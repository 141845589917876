.scroll-buttons-container {
  display: flex;
  align-items: center;
  position: relative;
}

.scroll-button {
  flex-shrink: 0;
  z-index: 1; /* Ensure buttons are above the tabs */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.tabs-container {
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
}

/* Ensure buttons are always visible */
.scroll-button.left {
  position: absolute;
  left: 0;
}

.scroll-button.right {
  position: absolute;
  right: 0;
}