.App {
  text-align:left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.faq-item {
  margin: 20px 0;
}

.question {
  background-color: #ddd;
  padding: 10px;
  cursor: pointer;
}

main {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
}

.answer {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: white;
}

.banner {
  background-image: url('/public/images/homebanner1.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  height: 200px; 
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex-direction: column;
  justify-content: center; 
  position: relative;
  color: white; 
  text-align: center; 
  box-sizing: border-box; 
  width: 100%; 
}  

.banner-text {
  font-family: 'Great Vibes', cursive; 
  font-size: 6vw; 
  text-align: center;
  text-shadow: 1px 1px 2px black;
  padding: 0 10px;
} 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h3 {
  cursor: pointer;
}

h3:hover {
  color: #007BFF;
}